import React, { useContext, useState, createContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { errorCodes, toastMessage } from 'helpers';

import { UserContext } from './UserProvider';

export const ResponseContext = createContext();

export const ResponseProvider = ({ children }) => {
  const [t] = useTranslation('error');
  const [initialized, setInitialized] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { logoutChannel } = useContext(UserContext);

  const processKnownError = (error, knownError) => {
    if (Object.values(errorCodes).some(e => knownError[e])) {
      return Promise.reject(error);
    }
    for (const err in knownError) {
      const message = knownError[err];
      toastMessage(enqueueSnackbar, closeSnackbar, t(message), 'error');
    }
  };

  useMemo(() => {
    if (!initialized) {
      axios.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          if (error?.response?.status === 401) {
            logoutChannel.postMessage('Logout');
          }
          const knownError = error?.response?.data?.errors;

          if (
            knownError[Number(errorCodes.NO_ACTIVE_TENANTS)] ||
            knownError[Number(errorCodes.USER_NOT_ACTIVE)]
          ) {
            toastMessage(enqueueSnackbar, closeSnackbar, t(Object.values(knownError)), 'warning');
          }
          if (knownError) {
            processKnownError(error, knownError);
          } else {
            toastMessage(enqueueSnackbar, closeSnackbar, t('undefinedError'), 'error');
          }
          return Promise.reject(error);
        }
      );
      setInitialized(true);
    }
  });

  const contextValue = useMemo(() => ({ initialized }), [initialized]);

  return <ResponseContext.Provider value={contextValue}>{children}</ResponseContext.Provider>;
};

ResponseProvider.propTypes = {
  children: PropTypes.node
};
