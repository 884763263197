import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { faTriangleExclamation } from 'resources/fontawesome/solid/faTriangleExclamation';

const useStyles = makeStyles({
  formField: props => ({
    '& .MuiOutlinedInput-root': {
      height: '50px',
      border: `1px solid ${props.palette.gray[400]}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
      '&:hover': {
        backgroundColor: props.palette.gray[50]
      },
      '&.Mui-focused': {
        border: `1px solid ${props.palette.gray.dark}`
      },
      '&.Mui-error': {
        border: `1px solid ${props.palette.error.main}`
      },
      '&.Mui-error.Mui-focused': {
        boxShadow: '0px 0px 3px 3px rgba(255, 225, 225, 1)'
      }
    },
    '& .MuiOutlinedInput-input': {
      fontFamily: props.typography.fontFamilyPrimaryRegular,
      color: props.palette.gray.dark,
      fontSize: props.typography.pxToRem(14),
      height: '17px',
      padding: 0,
      paddingLeft: '16px',
      '&::placeholder': {
        color: props.palette.gray[600],
        opacity: 1
      }
    },
    '& .MuiFormHelperText-root': props => ({
      paddingTop: '5px',
      color: props.palette.error.main + '!important',
      margin: '0px',
      fontFamily: props.typography.fontFamilyPrimaryRegular
    })
  }),
  errorIcon: props => ({
    height: '18px',
    width: '18px',
    color: props.palette.error.icon
  })
});

export const FormField = ({ dataCy, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { error } = props;

  return (
    <TextField
      {...props}
      className={classes.formField}
      InputProps={{
        endAdornment: error && (
          <InputAdornment position='end'>
            <FontAwesomeIcon icon={faTriangleExclamation} className={classes.errorIcon} />
          </InputAdornment>
        )
      }}
      inputProps={{
        'data-cy': dataCy
      }}
    />
  );
};

FormField.propTypes = {
  dataCy: PropTypes.string,
  error: PropTypes.bool
};
