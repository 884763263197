import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import 'i18n';
import i18next from 'i18next';

import { NotistackProvider, ScrollToTop } from 'components';
import { appVersion, sentryDSN, sentryEnvironment, sentrySampleRate, INVALID_TOTP } from 'helpers';
import { UserProvider } from 'providers';
import { ThemeConfig } from 'theme';

import { App } from './containers/App/App';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

Sentry.init({
  dsn: sentryDSN,
  release: appVersion,
  environment: sentryEnvironment,
  tracesSampleRate: sentrySampleRate,
  profilesSampleRate: 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false
    }),
    new Sentry.BrowserTracing(),
    new Sentry.BrowserProfilingIntegration()
  ],
  // Ignoring Sentry UnhandledRejection on account setup
  ignoreErrors: ['Object Not Found Matching Id'],
  beforeSend: (event, hint) => {
    const { response } = hint.originalException;
    const errors = response?.data?.errors;
    if (errors && Object.values(errors)[0] === INVALID_TOTP) {
      return null;
    }

    // remove access keys from breadcrumbs
    if (event?.breadcrumbs) {
      event.breadcrumbs = event.breadcrumbs.map(bc => {
        if (bc.data?.url?.includes('suggestionDensityHub')) {
          const url = new URL(bc.data.url);
          url.searchParams.delete('access_token');
          bc.data.url = url.href;
        }
        return bc;
      });
    }

    return event;
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const app = (
  <HelmetProvider>
    <I18nextProvider i18n={i18next}>
      <CollapseDrawerProvider>
        <BrowserRouter>
          <ScrollToTop />
          <ThemeConfig>
            <NotistackProvider>
              <QueryClientProvider client={queryClient}>
                <UserProvider>
                  <App />
                </UserProvider>
              </QueryClientProvider>
            </NotistackProvider>
          </ThemeConfig>
        </BrowserRouter>
      </CollapseDrawerProvider>
    </I18nextProvider>
  </HelmetProvider>
);

ReactDOM.render(app, document.getElementById('root'));
