import React from 'react';

import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { InputAdornment } from '@mui/material';
import axios from 'axios';
import { format, intervalToDuration } from 'date-fns';
import { capitalize, cloneDeep } from 'lodash';
import qs from 'qs';

import { Icons, MIconButton } from 'components';
import { customFilterConfig } from 'containers/ReportingAndAnalytics/methods';
import {
  COMMENT_PREFIX,
  checkPermission,
  dateRangeOptions,
  dateRangeValues,
  dateUnit,
  filterOptions,
  HEADER,
  mixpanel,
  permissions,
  scoreToReachData,
  sourcePageMapper,
  userCurrentStatus,
  metricTypeEnum
} from 'helpers';

import { filterDropdownOptions } from './enums';

export const handleFirebaseErrors = error => {
  if (!error) {
    return null;
  }
  switch (error.code) {
    case 'auth/invalid-email':
    case 'auth/wrong-password':
      return 'invalidCredential';
    case 'auth/user-disabled':
      return 'userDisabled';
    case 'auth/user-not-found':
      return 'invalidCredential';
    case 'auth/account-exists-with-different-credential':
      return 'accountExistsWithDifferentCredential';
    case 'auth/invalid-credential':
      return 'invalidCredential';
    case 'auth/operation-not-allowed':
      return 'operationNotAllowed';
    case 'auth/email-already-in-use':
      return 'accountExistsWithDifferentCredential';
    case 'auth/weak-password':
      return 'weakPassword';
    case 'auth/user-mismatch':
      return 'invalidCredential';
    case 'auth/invalid-action-code':
      return 'passwordResetError';
    case 'auth/too-many-requests':
      return 'userAccountDisabledByWrongPassword';
    case 'auth/network-request-failed':
      return 'networkIssue';
    default:
      return error.message;
  }
};

export const getParameterByName = value => {
  const url = window.location.search.slice(1);
  const parsed = qs.parse(url);
  return parsed[value];
};

export const formatDate = date => {
  return format(date, 'yyy-MM-dd');
};

export const toastMessage = (enqueueSnackbar, closeSnackbar, message, variant) => {
  return enqueueSnackbar(
    <div style={{ paddingTop: '4px' }} dangerouslySetInnerHTML={{ __html: message }} />,
    {
      variant,
      style: { whiteSpace: 'pre-wrap' },
      action: key => (
        <MIconButton size='small' onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    }
  );
};

export const handleShowPassword = (show, handleShow, testId) => {
  return (
    <InputAdornment position='end'>
      <div
        data-testid={testId}
        style={{
          marginRight: '10px',
          width: '20px',
          cursor: 'pointer',
          marginTop: '5px'
        }}
        onClick={() => handleShow(prev => !prev)}>
        <Icons iconName={show ? 'passwordVisible' : 'passwordInvisible'} />
      </div>
    </InputAdornment>
  );
};

export const handleCorrectPassword = errors => {
  return (
    <InputAdornment position='end' style={{ outline: 'none' }}>
      <div
        style={{
          marginLeft: '10px',
          width: '20px',
          marginRight: '20px',
          borderRadius: 'initial',
          border: 'transparent',
          marginTop: '5px'
        }}>
        <Icons iconName={errors ? 'passwordIncorrect' : 'passwordCorrect'} />
      </div>
    </InputAdornment>
  );
};

export const handleSelection = (e, id, selected, setSelected) => {
  const selectedIndex = selected.indexOf(id);
  let newSelected = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }
  setSelected(newSelected);
};

export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const throttle = (callback, delay = 1000) => {
  let shouldWait = false;

  return (...args) => {
    if (shouldWait) {
      return;
    }

    callback(...args);
    shouldWait = true;
    setTimeout(() => {
      shouldWait = false;
    }, delay);
  };
};

export const handleSearch = debounce((e, setSearch, setPage, groupedFilters, setGroupedFilters) => {
  setSearch(e.target.value);
  if (setPage) {
    setPage(0);
  }
  if (groupedFilters && setGroupedFilters) {
    setGroupedFilters({ ...groupedFilters, search: e.target.value });
  }
}, 250);

export const getFileNameFromUrl = url => {
  if (url) {
    const segments = new URL(url).pathname.split('/');
    return segments[segments.length - 1];
  }
};

export const calculateIndices = (text, selectedWord, exact) => {
  const indices = [];
  if (selectedWord?.match(/^\s+$/) === null && selectedWord.length > 1) {
    const re = new RegExp(exact ? `\\b${selectedWord}\\b` : escapeRegex(selectedWord), 'gi');
    let match;
    while ((match = re.exec(text)) !== null) {
      indices.push(match.index);
    }
  }
  return indices;
};

export const initializeMixpanel = user => {
  mixpanel.init();
  mixpanel.identify(user.externalId);
  const currentTenant = user.tenants.find(x => x.isDefault);
  mixpanel.register({
    Team: user?.team?.name || null,
    'Company name': currentTenant.name
  });
  mixpanel.setUser({
    $name: user.displayName,
    $email: user.email,
    'External id': user.externalId
  });
};

export const isInsert = change => {
  return change?.insert;
};

export const escapeRegex = (term, isExactMatch) => {
  // eslint-disable-next-line no-useless-escape
  const regex = term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  return isExactMatch ? '\\b' + regex + '\\b' : regex;
};

export const sortLabels = labels => {
  const unsortedLabels = labels || [];
  return unsortedLabels.sort((a, b) => a.name.localeCompare(b.name));
};

export const getUserStatusColor = (theme, userStatus) => {
  const userStatusColor = {
    [userCurrentStatus.INVITED]: theme.palette.complexWord.main,
    [userCurrentStatus.ACTIVE]: theme.palette.tertiary.main,
    [userCurrentStatus.DEACTIVATED]: theme.palette.gray[500],
    [userCurrentStatus.LOCKED]: theme.palette.disabledUser.main
  };
  return userStatusColor[userStatus];
};

export const detectMouseDownEvent = handleEvent => {
  document.addEventListener('mousedown', handleEvent, false);
  return () => {
    document.removeEventListener('mousedown', handleEvent, false);
  };
};

const levelExplanationBreakpoints = [
  [81, 'level1'],
  [71, 'level2'],
  [66, 'level3_high'],
  [61, 'level3_low'],
  [51, 'level4_high'],
  [41, 'level4_low'],
  [31, 'level5_high'],
  [0, 'level5_low']
];

export const getIntelligibilityLevelDescription = (t, score) => {
  for (const lvlExpl of levelExplanationBreakpoints) {
    if (score >= lvlExpl[0]) {
      return t(`scoreExplanation_${lvlExpl[1]}`, { score });
    }
  }

  return t('scoreExplanation_level5_low', { score: 0 });
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = componentImport => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport()
      .then(component => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        if (component === undefined) {
          //case where webpack returns undefined instead of throwing an error
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behavior as already tried refresh
      });
  });
};

export const getParentElementWithTag = (node, tagValue) => {
  if (!node) {
    return null;
  }
  let parentNode = node.parentNode;
  while (parentNode) {
    if (parentNode.tagName === tagValue) {
      return parentNode;
    }
    parentNode = parentNode.parentNode;
  }
  return null;
};

export const checkRequestLoggingToConsole = (userPermissions, request) => {
  return (
    checkPermission(userPermissions, permissions.USERS_TRACK_ML_REQUESTS) &&
    request?.requestId &&
    request?.cacheId &&
    request?.requestType
  );
};

export const handleChangeLabels = debounce((selectedLabelsValue, setSelectedLabels) => {
  setSelectedLabels(selectedLabelsValue);
}, 700);

const levelBreakpoints = [
  [81, 1],
  [71, 2],
  [61, 3],
  [41, 4],
  [1, 5]
];

export const getIntelligibilityLevelFromScore = score => {
  for (const levelBreakpoint of levelBreakpoints) {
    if (score >= levelBreakpoint[0]) {
      return levelBreakpoint[1];
    }
  }
  return 0;
};

export const improvementPercentage = improvementValue => {
  return Intl.NumberFormat('en-US', {
    signDisplay: 'exceptZero'
  }).format(improvementValue);
};

export const formatDuration = seconds => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const { days, hours, minutes } = duration;
  const formattedDays = days * 24 || '';
  const formattedHours = formattedDays || hours ? `${hours + formattedDays}h` : '';
  return `${formattedHours} ${minutes}m`;
};

export const getFormattedDateAndTime = (date, includeTime = true) => {
  return format(new Date(date), includeTime ? 'h:mm a, dd/MM/yyyy' : 'dd/MM/yyyy');
};

export const handleChangePage = (e, newPage, setPage) => {
  setPage(newPage);
};

export const handleChangeRowsPerPage = (e, setRowsPerPage, setPage) => {
  setRowsPerPage(parseInt(e.target.value, 10));
  setPage(0);
};

export const getStringWithoutSpaces = string => {
  return string.replaceAll(' ', '');
};

export const getColorBasedOnScore = (score, theme) => {
  switch (score) {
    case 'bad':
      return theme.palette.error.main;
    case 'average':
      return theme.palette.warning.main;
    case 'good':
      return theme.palette.success.main;
  }
};

export const roundNumber = (number, decimalPoints = 0) => Number(number.toFixed(decimalPoints));

export const metricTypeColorLevel = (metricType, status) => {
  switch (metricType) {
    case metricTypeEnum.COMPLEXITY_SCORE:
      return `complexityLevel_${status}`;
    case metricTypeEnum.INTELLIGIBILITY_SCORE:
      return `intelligibility_${status}`;
  }
};

export const getColorBasedOnImprovement = (
  score,
  theme,
  isAscendingImprovement,
  isDarkBackground
) => {
  if (score < 0) {
    return isAscendingImprovement ? theme.palette.active.dark : theme.palette.error.main;
  }
  if (score === 0) {
    return isDarkBackground ? theme.palette.gray.white : theme.palette.common.black;
  }
  if (score > 0) {
    return isAscendingImprovement ? theme.palette.error.main : theme.palette.active.dark;
  }
};

export const getProjectedReach = score => {
  for (const reachData of scoreToReachData) {
    if (score >= reachData.low && score <= reachData.high) {
      return reachData.reach;
    }
  }
};

export const formatSelections = (selectionsArray, t) => {
  const nameArray = selectionsArray.value.map(item => t(item.name));
  return nameArray.join(` ${t('or').toUpperCase()} `);
};

export const formatFilterChipText = (item, t) => {
  if ([filterOptions.CREATED_BY, filterOptions.EDITED_BY].includes(item.filterOption)) {
    return `${t(item.filterOption).toLowerCase()} ${t(item.category).slice(0, -1).toLowerCase()} :
    ${formatSelections(item, t)}`;
  } else if (item.filterOption === filterOptions.LEVELS) {
    return `${t('currentScore').toLowerCase()} : ${formatSelections(item, t)}`;
  } else if (
    item.filterOption === filterDropdownOptions.EDITS ||
    item.filterOption === filterDropdownOptions.TYPES
  ) {
    return `${item.category} : ${formatSelections(item, t)}`;
  } else {
    return `${t(item.category).slice(0, -1).toLowerCase()} ${t(
      item.filterOption
    ).toLowerCase()} : ${formatSelections(item, t)}`;
  }
};

export const getFormattedSelectedFilterOptions = selectedFilterOptions => {
  return Object.keys(selectedFilterOptions).flatMap(category =>
    Object.keys(selectedFilterOptions[category]).flatMap(filterOption =>
      selectedFilterOptions[category][filterOption].map(element => ({
        category: category,
        filterOption: filterOption,
        value: element
      }))
    )
  );
};

export const getCustomDatePickerText = t => {
  const fromText = capitalize(t('from'));
  const toText = capitalize(t('to'));
  return `${fromText} - ${toText}`;
};

export const getSelectedDateValue = (t, period, isUnitDay = false) => {
  return t(isUnitDay ? 'filteredPeriodDays' : 'filteredPeriodMonths', {
    number: period
  });
};

export const getDateRangeFilters = (t, selectedDateFilter) => {
  const dateRangeFilters = [
    customFilterConfig(selectedDateFilter),
    {
      id: dateRangeValues.TODAY,
      period: 1,
      unit: dateUnit.DAY,
      displayValue: 'today',
      filter: 'today'
    },
    {
      id: dateRangeValues.YESTERDAY,
      period: 1,
      unit: dateUnit.DAY,
      displayValue: 'yesterday',
      filter: 'yesterday'
    },
    {
      id: dateRangeValues.SEVEN_DAYS,
      filter: '7D',
      period: 7,
      unit: dateUnit.DAY,
      displayValue: getSelectedDateValue(t, 7, true)
    },
    {
      id: dateRangeValues.THIRTY_DAYS,
      filter: '30D',
      period: 30,
      unit: dateUnit.DAY,
      displayValue: getSelectedDateValue(t, 30, true)
    },
    {
      id: dateRangeValues.THREE_MONTHS,
      filter: '3M',
      period: 3,
      unit: dateUnit.MONTH,
      displayValue: getSelectedDateValue(t, 3)
    },
    {
      id: dateRangeValues.SIX_MONTHS,
      filter: '6M',
      period: 6,
      unit: dateUnit.MONTH,
      displayValue: getSelectedDateValue(t, 6)
    },
    {
      id: dateRangeValues.TWELVE_MONTHS,
      filter: '12M',
      period: 12,
      unit: dateUnit.MONTH,
      displayValue: getSelectedDateValue(t, 12)
    },
    {
      id: dateRangeValues.ALL_TIME,
      filter: 'allTime',
      period: 12,
      unit: dateUnit.MONTH,
      displayValue: t('allTime')
    }
  ];
  return dateRangeFilters;
};

export const handleSelectedDate = (
  dateRangeFilters,
  groupedFilters,
  setFilteredPeriod,
  setSelectedDateFilter,
  setTextFieldValue,
  INITIAL_DATE_FILTER,
  t
) => {
  const selectedDate = dateRangeFilters.find(item => item.id === groupedFilters.id);
  if (selectedDate) {
    if (selectedDate.id === dateRangeValues.CUSTOM && selectedDate.startDate) {
      setFilteredPeriod(`${selectedDate.startDate}-${selectedDate.endDate}`);
    } else if (selectedDate.id === dateRangeValues.CUSTOM && !selectedDate.startDate) {
      setSelectedDateFilter(INITIAL_DATE_FILTER);
      setTextFieldValue(getCustomDatePickerText(t));
    } else {
      setFilteredPeriod(selectedDate.displayValue);
    }
  }
};

export const filterSearchedProperties = (data, search) =>
  search === ''
    ? data
    : data.filter(label => label.name.toLowerCase().includes(search.toLowerCase()));

export const formatNumber = number => {
  return number.toLocaleString();
};

export const collectIds = array => {
  return array.map(item => item.id).flat();
};

export const transformFilterData = filterValues => {
  const resultArray = [];

  filterValues.forEach(item => {
    if (item.contains?.length > 0) {
      resultArray.push({
        property: item.property,
        operation: filterOptions.CONTAINS,
        value: item.contains[0].name
      });
    }

    if (item.equals?.length > 0) {
      resultArray.push({
        property: item.property,
        operation: filterOptions.EQUALS,
        value: collectIds(item.equals)
      });
    }

    if (item.doesNotContain?.length > 0) {
      resultArray.push({
        property: item.property,
        operation: filterOptions.DOES_NOT_CONTAIN,
        value: item.doesNotContain[0].name
      });
    }

    if (item.doesNotEqual?.length > 0) {
      resultArray.push({
        property: item.property,
        operation: filterOptions.DOES_NOT_EQUAL,
        value: collectIds(item.doesNotEqual)
      });
    }

    if (item.createdBy?.length > 0) {
      resultArray.push({
        property: item.property,
        operation: filterOptions.CREATED_BY,
        value: collectIds(item.createdBy)
      });
    }

    if (item.editedBy?.length > 0) {
      resultArray.push({
        property: item.property,
        operation: filterOptions.EDITED_BY,
        value: collectIds(item.editedBy)
      });
    }

    if (item.levels?.length > 0) {
      resultArray.push({
        property: item.property,
        value: collectIds(item.levels)
      });
    }

    if (item.edits?.length === 1) {
      resultArray.push({
        property: item.property,
        value: item.edits[0].id
      });
    }

    if (item.types?.length > 0) {
      resultArray.push({
        property: item.property,
        value: collectIds(item.types)
      });
    }
  });

  return resultArray;
};

export const formatFilters = (filter, rowsPerPage, page, orderBy, search) => {
  const getSelectedFilterByEnumValue = value => {
    const selectedFilterKey = Object.keys(dateRangeValues).find(
      key => dateRangeValues[key] === value
    );
    return dateRangeOptions[selectedFilterKey];
  };

  const selectedFilter = getSelectedFilterByEnumValue(filter?.id);
  const dateAndOffsetFilter = [
    { property: 'offset', value: page * rowsPerPage },
    { property: 'limit', value: rowsPerPage },
    { property: 'orderBy', value: orderBy },
    { property: 'search', value: search },
    {
      property: 'dateRange',
      operation: selectedFilter,
      value: selectedFilter === dateRangeOptions.CUSTOM ? filter?.date : []
    }
  ];

  const dropdownFilters = Object.keys(filter).map(property => ({
    property,
    ...filter[property]
  }));
  const formattedFilterData = dateAndOffsetFilter.concat(transformFilterData(dropdownFilters));
  return formattedFilterData.filter(item => item.value);
};

export const mergeSelectedFilterOptions = formattedSelectedFilterOptions => {
  const mergedArray = formattedSelectedFilterOptions.reduce((acc, currentItem) => {
    const existingItem = acc.find(
      item =>
        item.category === currentItem.category && item.filterOption === currentItem.filterOption
    );

    if (existingItem) {
      existingItem.value = [...existingItem.value, currentItem.value];
    } else {
      acc.push({ ...currentItem, value: [currentItem.value] });
    }

    return acc;
  }, []);

  return mergedArray;
};

export const partialObjectUpdate = (url, path, propertyToUpdate) => {
  return axios
    .patch(
      url,
      [
        {
          op: 'replace',
          path: path,
          value: propertyToUpdate
        }
      ],
      {
        headers: {
          'content-type': 'application/json-patch+json'
        }
      }
    )
    .then(res => {
      return res.data;
    });
};

const isIntervalContained = (intervals, interval) => {
  let left = 0;
  let right = intervals.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const { startIndex, endIndex } = intervals[mid];

    if (startIndex <= interval[0] && interval[1] <= endIndex) {
      return true; // Interval is contained in intervals[mid]
    } else if (interval[1] < startIndex) {
      right = mid - 1; // Search in the left half
    } else {
      left = mid + 1; // Search in the right half
    }
  }

  return false; // Interval is not contained in any interval
};

export const applyFormattingOnSubintervals = (theme, differences, suggestions, text) => {
  const formattedSimplification = [];
  const diffIndexes = differences.flatMap(item => {
    return [item.startIndex, item.endIndex];
  });
  const suggIndexes = suggestions.flatMap(item => {
    return [item.startIndex, item.endIndex];
  });
  const mergedIntervals = [...new Set([...diffIndexes, ...suggIndexes, 0, text.length])].sort(
    (a, b) => a - b
  );

  const sortedDifferences = differences.sort((a, b) => a.startIndex - b.startIndex);
  const sortedSuggestions = suggestions.sort((a, b) => a.startIndex - b.startIndex);

  for (let i = 0; i < mergedIntervals.length - 1; i++) {
    const interval = [mergedIntervals[i], mergedIntervals[i + 1]];
    const isContainedInDifferences = isIntervalContained(sortedDifferences, interval);
    const isContainedInSuggestions = isIntervalContained(sortedSuggestions, interval);
    if (isContainedInDifferences && isContainedInSuggestions) {
      formattedSimplification.push(
        <span
          style={{
            backgroundColor: theme.palette.quinary.main,
            textDecoration: `underline ${theme.palette.quaternary.main}`,
            textDecorationThickness: '2px'
          }}>
          {text.substring(interval[0], interval[1])}
        </span>
      );
    } else if (isContainedInDifferences) {
      formattedSimplification.push(
        <span
          style={{
            backgroundColor: theme.palette.quinary.main
          }}>
          {text.substring(interval[0], interval[1])}
        </span>
      );
    } else if (isContainedInSuggestions) {
      formattedSimplification.push(
        <span
          style={{
            textDecoration: `underline ${theme.palette.quaternary.main}`,
            textDecorationThickness: '2px'
          }}>
          {text.substring(interval[0], interval[1])}
        </span>
      );
    } else {
      formattedSimplification.push(<span>{text.substring(interval[0], interval[1])}</span>);
    }
  }
  return formattedSimplification;
};

export const applyUnderliningOnSubintervals = (theme, suggestions, text) => {
  const formattedSimplification = [];
  const suggIndexes = suggestions.flatMap(item => [item.startIndex, item.endIndex]);
  const mergedIntervals = [...new Set([...suggIndexes, 0, text.length])].sort((a, b) => a - b);

  const sortedSuggestions = suggestions.sort((a, b) => a.startIndex - b.startIndex);

  for (let i = 0; i < mergedIntervals.length - 1; i++) {
    const interval = [mergedIntervals[i], mergedIntervals[i + 1]];
    const isContainedInSuggestions = isIntervalContained(sortedSuggestions, interval);

    if (isContainedInSuggestions) {
      formattedSimplification.push(
        <span
          style={{
            textDecoration: `underline ${theme.palette.quaternary.main}`,
            textDecorationThickness: '2px'
          }}>
          {text.substring(interval[0], interval[1])}
        </span>
      );
    } else {
      formattedSimplification.push(<span>{text.substring(interval[0], interval[1])}</span>);
    }
  }

  return formattedSimplification;
};

export const trackAppliedFilters = (t, page) => {
  const sourcePageName = sourcePageMapper[page];
  mixpanel.track('Filters applied', {
    page: t(sourcePageName)
  });
};

export const handleLabelChange = (item, selectedLabels, setSelectedLabels) => {
  let updatedList = [...selectedLabels];

  const itemIndex = updatedList.findIndex(i => {
    return i.id === item.id;
  });

  if (itemIndex === -1) {
    updatedList = [...selectedLabels, item];
  } else {
    updatedList.splice(itemIndex, 1);
  }

  setSelectedLabels(updatedList);
};

// Method to find out if the menu item selected
// Menu item is selected if it exists only in one of the lists (existing or selected)
export const isLabelMenuItemChecked = (item, existingLabels, selectedLabels) => {
  // If the list is undefined or there is no item with the same id,
  // the result should be -1 in both cases, for the final check to be valid
  const exist = existingLabels
    ? existingLabels.findIndex(i => {
        return i.id === item.id;
      })
    : -1;

  const selected = selectedLabels
    ? selectedLabels.findIndex(i => {
        return i.id === item.id;
      })
    : -1;

  return (exist !== -1) !== (selected !== -1);
};

const checkTagType = (sectionsDimensionData, index) => {
  const headerTag = sectionsDimensionData[index]?.headingTag;
  const height = sectionsDimensionData[index]?.height;
  switch (headerTag) {
    case HEADER.TAG_1:
      return -7;
    case HEADER.TAG_2:
      return -6;
    case HEADER.TAG_3:
      return -5;
  }
  if (height < 24) {
    return -4;
  }
  return -7;
};

const checkTagTypeForComments = (sectionsDimensionData, index) => {
  const headerTag = sectionsDimensionData[index]?.headingTag;
  switch (headerTag) {
    case HEADER.TAG_1:
      return 10;
    case HEADER.TAG_2:
      return 15;
    case HEADER.TAG_3:
      return 20;
  }
  return 10;
};

export const calculateIndicatorPosition = (sectionsDimensionData, index, isCommentIndicator) => {
  if (sectionsDimensionData.length) {
    const switchIndicatorType = isCommentIndicator
      ? checkTagTypeForComments(sectionsDimensionData, index)
      : checkTagType(sectionsDimensionData, index);
    return sectionsDimensionData[index]?.offsetTop - switchIndicatorType;
  }
};

const toggleClassOnElements = (elements, operation) => {
  for (const element of elements) {
    element.classList[operation]('comment');
  }
};

export const changeCommentsHighlighting = (isHighlightingComment, parentCommentId, commentId) => {
  if (isHighlightingComment) {
    const elements = document.getElementsByClassName(`${COMMENT_PREFIX}${parentCommentId}`);
    toggleClassOnElements(elements, 'add');
  } else {
    const elements = document.getElementsByClassName(`${COMMENT_PREFIX}${commentId}`);
    toggleClassOnElements(elements, 'remove');
  }
};

const toggleActiveClassOnElements = (elements, operation) => {
  for (const element of elements) {
    element.classList[operation]('activeComment');
  }
};

export const changeActiveCommentsHighlighting = (isHighlightingComment, commentId) => {
  const elements = document.getElementsByClassName(`${COMMENT_PREFIX}${commentId}`);
  toggleActiveClassOnElements(elements, isHighlightingComment ? 'add' : 'remove');
};

export const scrollToElementIfNeeded = (element, scrollRef) => {
  const newActiveHighlightOffsetTop = element?.getBoundingClientRect().top;
  const currentDocumentScrollTop = scrollRef.current?.scrollTop;
  const currentWindowHeight = window.innerHeight;
  const editorHeight = scrollRef.current.clientHeight;
  const textLineHeight = 50;

  let scrollValue;

  if (
    newActiveHighlightOffsetTop >= 0 &&
    newActiveHighlightOffsetTop > currentWindowHeight - textLineHeight
  ) {
    scrollValue =
      currentDocumentScrollTop +
      (newActiveHighlightOffsetTop - currentWindowHeight) +
      currentWindowHeight / 2;
    // check if next searched result (newActiveHighlightOffsetTop) is out of the viewport (currentWindowHeight) minus the height of one text line (textLineHeight)
    // for escaping highlighting words that are cut by the editor border
    // on current scroll value (currentDocumentScrollTop) the difference between how much the next searched result (expression in the brackets) is away from the viewport is added,
    //also half value of the viewport is added (currentWindowHeight / 2) to position the highlighted result in the middle of the editor
  } else if (
    newActiveHighlightOffsetTop >= 0 &&
    newActiveHighlightOffsetTop < currentWindowHeight - editorHeight
  ) {
    scrollValue =
      currentDocumentScrollTop -
      (currentWindowHeight - editorHeight - newActiveHighlightOffsetTop) -
      currentWindowHeight / 2;
    // check if previous searched result is on the upper side of the editor in area between viewport and editor input
  } else if (newActiveHighlightOffsetTop < 0) {
    // check if next or previous searched results is out of the viewport, for cases when navigating from last result on the page to the first
    scrollValue = currentDocumentScrollTop + newActiveHighlightOffsetTop - currentWindowHeight / 2;
  }

  scrollRef.current.scrollTo({
    top: scrollValue,
    behavior: 'smooth'
  });
};

export const switchCommentFormatOnParagraphLevel = (quillEditor, commentSelection) => {
  const [line] = quillEditor.getLine(commentSelection.index);
  const index = quillEditor.getIndex(line);
  const length = line.cache.length;
  return { index: index, length: length };
};

export const scrollToOverflowingDialog = (element, scrollRef) => {
  const dialogOffsetTop = element?.getBoundingClientRect().top;
  const documentScrollTop = scrollRef?.current?.scrollTop;
  const currentWindowHeight = window.innerHeight;
  const editorHeight = scrollRef.current.clientHeight;
  const dialogHeight = element?.getBoundingClientRect().height;
  // space from top of the viewport to the top of the editor
  const spaceAboveEditor = currentWindowHeight - editorHeight;
  // space from top of the editor to the top of the dialog
  const spaceFromEditorTopToDialogHeader = dialogOffsetTop - spaceAboveEditor;
  // check if dialog is overflowing the editor space and if it is scroll the document enough to make the dialog visible
  if (spaceFromEditorTopToDialogHeader + dialogHeight > editorHeight) {
    scrollRef.current.scrollTop =
      spaceFromEditorTopToDialogHeader + dialogHeight - editorHeight + documentScrollTop + 30;
  }
};

export const getCommentIdsWithoutPrefix = ids => {
  return ids?.map(id => id.replace(`${COMMENT_PREFIX}`, ''));
};

export const getElementsWithoutCommentClasses = (elements, id) => {
  const elementsArr = Array.from(elements);
  if (elementsArr.length > 0) {
    elementsArr.forEach(ar => {
      ar.classList.remove(`${COMMENT_PREFIX}${id}`);
      ar.classList.remove('comment');
    });
    if (!elementsArr[0].classList.length) {
      elementsArr[0].removeAttribute('class');
    }
  }
};

//function for removing comment id class and style attribute when whole paragraph is selected
export const removeCommentFormatOnParagraph = (quillEditor, commentSelection, removeId) => {
  const commentRange = switchCommentFormatOnParagraphLevel(quillEditor, commentSelection);
  removeId &&
    quillEditor?.formatText(commentRange.index, commentRange.length, 'comment', false, 'user');
  quillEditor?.formatText(commentRange.index, commentRange.length, 'highlightComment', false);
};

//function for removing comment id class and style attribute when part of paragraph is selected
export const removeCommentFormatOnSelection = (quillEditor, commentSelection, removeId) => {
  removeId &&
    quillEditor?.formatText(
      commentSelection?.index,
      commentSelection?.length,
      'comment',
      false,
      'user'
    );
  quillEditor?.formatText(
    commentSelection.index,
    commentSelection.length,
    'highlightComment',
    false
  );
};

export const checkIfAnyArrayHasElements = obj => {
  for (const prop in obj) {
    if (Array.isArray(obj[prop]) && obj[prop].length > 0) {
      return true;
    }
  }
  return false;
};

export const getActiveFilterCountReportingPage = groupedFilters => {
  return (
    (checkIfAnyArrayHasElements(groupedFilters.teams) ? 1 : 0) +
    (checkIfAnyArrayHasElements(groupedFilters.users) ? 1 : 0) +
    (groupedFilters.date || (!groupedFilters.date && groupedFilters.id !== 0) ? 1 : 0) +
    (checkIfAnyArrayHasElements(groupedFilters.labels) ? 1 : 0) +
    (checkIfAnyArrayHasElements(groupedFilters.editors) ? 1 : 0) +
    (checkIfAnyArrayHasElements(groupedFilters.editorTeams) ? 1 : 0) +
    (checkIfAnyArrayHasElements(groupedFilters.creators) ? 1 : 0) +
    (checkIfAnyArrayHasElements(groupedFilters.creatorTeams) ? 1 : 0)
  );
};

export const stringifyCommentValues = deltaChanges => {
  const deltaChangesCopy = cloneDeep(deltaChanges);
  const deltaChangesWithStringifiedCommentAttribute = deltaChangesCopy.ops.map(op => {
    if (op?.attributes?.comment) {
      op.attributes.comment = JSON.stringify(op.attributes.comment);
    }
    return op;
  });
  return { ops: deltaChangesWithStringifiedCommentAttribute };
};

export const highlighParagraphViaCommentTag = (id, operation) => {
  const node = document.getElementsByClassName(id);
  if (node?.[0]) {
    const parentNode = node[0].parentNode;
    const siblings = parentNode.childNodes;
    siblings?.forEach(sibling => {
      // Check if the sibling is an element node
      if (sibling.nodeType === Node.ELEMENT_NODE && sibling.className !== '') {
        sibling.classList[operation]('comment');
      }
    });
  }
};

const removeCurrentlyAddedCommentId = commentId => {
  const elements = document.getElementsByClassName(`${COMMENT_PREFIX}${commentId}`);
  getElementsWithoutCommentClasses(elements, commentId);
};

// If a new selection is made, without adding a comment to a previous one, comment class and style attributes on previous selection are removed.
export const removePreviousSelectionWhileAddingComments = (
  commentSelection,
  quillEditor,
  commentId
) => {
  if (commentSelection.length === 0) {
    removeCurrentlyAddedCommentId(commentId);
    removeCommentFormatOnParagraph(quillEditor, commentSelection);
  } else if (commentSelection.length !== -1) {
    removeCurrentlyAddedCommentId(commentId);
    removeCommentFormatOnSelection(quillEditor, commentSelection);
  }
};

export const checkParagraphEmptyStatus = (sectionsInfo, paragraphIndex) => {
  return sectionsInfo?.find(
    item => item.startIndex <= paragraphIndex && item.endIndex >= paragraphIndex
  )?.isEmpty;
};

export const formatWordSuggestions = wordSuggestions => {
  return wordSuggestions
    ?.map(sugg => {
      return `'${sugg.text}'`;
    })
    .join(', ');
};

export const filterSuggestionsPerRange = (startIndex, endIndex, documentSuggestions) =>
  documentSuggestions?.filter(
    item =>
      startIndex <= item.suggestionRange.startIndex && endIndex >= item.suggestionRange.endIndex
  );
